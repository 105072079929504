import React, { useEffect, useState } from "react"
import { DocumentViewer } from 'react-documents';

function App({ url, setQuit }) {
    const [height, setHeight] = useState(0)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        window.addEventListener('resize', () => {
            setHeight(window.innerHeight);
        })
        setHeight(window.innerHeight);
    }, [])

    return (
        <div style={{ width: "100%", height: height, position: "relative", overflow:"hidden" }}>
            <div className="QuitFile" onClick={() => setQuit(undefined)}>
                X
            </div>
            <DocumentViewer
                style={{ width: "100%", height: "100%" }}
                queryParams="hl=En"
                url={url}
                viewer="pdf"
                googleCheckContentLoaded={true}
                loaded={() => {
                    setLoading(false)
                }}
            />
            {
                loading ?
                    <div className="CenterLoadingSpinner">
                        <span class="loader"></span>
                    </div> : null
            }
        </div>
    );
}

export default App;
