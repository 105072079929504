import React from "react"

function App({name, desc, img, setVideo, videoId }) {
    return (
        <div className="videoWrapper" onClick={() => setVideo(videoId)}>
            <div style={{ padding:10}}>
                <div style={{ width: "100%", backgroundColor: "black", cursor:"pointer" }} >
                    <img src={img} style={{width:"100%"}} />
                </div>
                <div className="TextVideoName">
                    {name}
                </div>
                <div className="SubTextVideoName">
                    {desc}
                </div>
            </div>
        </div>
    );
}

export default App;
