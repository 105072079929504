import React from "react"

function App({ text }) {
    return (
        <div style={{width:"93%", marginLeft: "auto", marginRight: "auto",padding:10}}>
            <div style={{ color: "black", fontSize: 20, width: "100%", marginLeft: "auto", marginRight: "auto", borderRadius: 15, paddingTop: 40, paddingBottom: 40 }} className="BlockColor2 BlueText">
                <div style={{ paddingLeft: 20 }}>
                    {text}
                </div>
            </div>
        </div>
    );
}

export default App;
