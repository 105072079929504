import React from "react"

function App({text, currentTab, onPressSet}) {
  var style = {width:"50%", 
  textAlign:"center",
  display:"inline-block", 
  paddingTop:15,paddingBottom:15, 
  borderTopLeftRadius:15,
  borderTopRightRadius:15, 
  position:"relative",
  cursor:"pointer",
};
  if (currentTab === text) {
    style.zIndex = 5;
    style.boxShadow = "0px -2.5px 3px rgba(0,0,0,0.2)";
  }
    return (
      <div style={style} className="BlockColor BlueText TabTitle" onClick={() => onPressSet(text)}>
        {text}
      </div>
    );
  }
  
  export default App;
  