const documents = [
    {
        name: "00. Biopharma_Brochure for HCPs",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/00. Biopharma_Brochure for HCPs.pdf",
    },
    {
        name: "01. ADALIMUMAB_Phase III AURIEL PsO_Hercogová J et al. 2019",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/01. ADALIMUMAB_Phase III AURIEL PsO_Hercogová J et al. 2019.pdf",
    },
    {
        name: "02. ADALIMUMAB_Phase I AI vs PFS_Sabet et al. 2022",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/02. ADALIMUMAB_Phase I AI vs PFS_Sabet et al. 2022.pdf",
    },
    {
        name: "03. TOCILIZUMAB PhI SC_Schwabe et al. 2022",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/03. TOCILIZUMAB PhI SC_Schwabe et al. 2022.pdf",
    },
    {
        name: "04. TOCILIZUMAB PhI AI vs PFS_Tomaszewska-Kiecana et al. 2023",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/04. TOCILIZUMAB PhI AI vs PFS_Tomaszewska-Kiecana et al. 2023.pdf",
    },
    {
        name: "05. TOCILIZUMAB PhI PKPD IV_Tomaszewska-Kiecana et al. 2023",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/05. TOCILIZUMAB PhI PKPD IV_Tomaszewska-Kiecana et al. 2023.pdf",
    },
    {
        name: "06. Baumgart (2019)_Can Biosimilars reduce access inequalities",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/06. Baumgart (2019)_Can Biosimilars reduce access inequalities.pdf",
    },
    {
        name: "07. Edwards et al. 2019_Switching to biosimilars",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/07. Edwards et al. 2019_Switching to biosimilars.pdf",
    },
    {
        name: "08. Taylor et al._2021 Co-morbidities paper",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/08. Taylor et al._2021 Co-morbidities paper.pdf",
    },
    {
        name: "09. Rheumatoid Arthritis_Pathophysiology of Disease",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/09. Rheumatoid Arthritis_Pathophysiology of Disease.pdf",
    },
    {
        name: "10. Ankylosing Spondylitis_Pathophysiology of Disease",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/10. Ankylosing Spondylitis_Pathophysiology of Disease.pdf",
    },
    {
        name: "11. Psoriatic Arthritis_Pathophysiology of Disease",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/11. Psoriatic Arthritis_Pathophysiology of Disease.pdf",
    },
    {
        name: "14. Autoimmune Disease_The Fundamentals",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/14. Autoimmune Disease_The Fundamentals.pdf",
    },
    {
        name: "ADALIMUMAB CMC_DATA_Infographic",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/ADALIMUMAB CMC_DATA_Infographic.pdf",
    },
    {
        name: "ADALIMUMAB Phase I AI vs PFS infographic",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/ADALIMUMAB Phase I AI vs PFS infographic.pdf",
    },
    {
        name: "ADALIMUMAB Phase I PK_PD_Infographic",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/ADALIMUMAB Phase I PK_PD_Infographic.pdf",
    },
    {
        name: "ADALIMUMAB Phase III AURIEL-PsO infographic",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/ADALIMUMAB Phase III AURIEL-PsO infographic.pdf",
    },
    {
        name: "TOCILIZUMAB GCA Pathophysiology of Disease",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/TOCILIZUMAB GCA Pathophysiology of Disease.pdf",
    },
    {
        name: "TOCILIZUMAB JIA Pathophysiology of Disease",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/TOCILIZUMAB JIA Pathophysiology of Disease.pdf",
    },
    {
        name: "TOCILIZUMAB Mechanism of Action",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/TOCILIZUMAB Mechanism of Action.pdf",
    },
    {
        name: "TOCILIZUMAB Phase I AI v PFS infographic",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/TOCILIZUMAB Phase I AI v PFS infographic.pdf",
    },
    {
        name: "TOCILIZUMAB Phase I IV infographic",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/TOCILIZUMAB Phase I IV infographic.pdf",
    },
    {
        name: "TOCILIZUMAB Phase I SC infographic",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/TOCILIZUMAB Phase I SC infographic.pdf",
    },
    {
        name: "TOCILIZUMAB PhIII Zubrzycka-Sienkiewicz et al 2024",
        url: "https://api.freseniusvienna2024.com/api/fresenius-2024-api/files/TOCILIZUMAB PhIII Zubrzycka-Sienkiewicz et al 2024.pdf",
    },
]

export default documents;