import './App.css';
import Tab from "./Components/Tab";
import Logo from "./assets/logo.png";
import React, { useEffect, useState } from "react";
import Instruction from "./Components/Instruction";
import Document from "./Components/Document";
import docuList from './docuList';
import VideoMinia from "./Components/Video";
import VideoPlayer from "./Components/VideoPlayer";
import FileReader from './Components/FileReader'
import axios from "axios";
import Cookies from 'js-cookie';

import miniaAntho from "./assets/minia-anthony.png";
import miniaEugen from "./assets/minia-eugen.png";
import miniaJerome from "./assets/minia-jerome.png";
import miniaJoao from "./assets/minia-joao.png";
import miniaMarco from "./assets/minia-marco.png";
import miniaSarah from "./assets/minia-sarah.png";

import videoJoao from "./assets/Video/MI20567 FK FRAME Budapest 01 Pr FONESCA ANW APPROVED.mp4";
import videoEugen from "./assets/Video/MI20567 FK FRAME Budapest 07 Pr EUGEN FEIST ANW APPROVED.mp4";
import videoAntho from "./assets/Video/MI20567 FK FRAME Budapest 03 Pr ANTHONY GORDON ANW APPROVED.mp4";
import videoMarco from "./assets/Video/MI20567 FK FRAME Budapest 02 Pr GATTORNO ANW APPROVED.mp4";
import videoSarah from "./assets/Video/MI20567 FK FRAME Budapest 06 Pr SARAH MACKIE ANW APPROVED.mp4";
import videoJerome from "./assets/Video/MI20567 FK FRAME Budapest 05 Pr JEROME AVOUAC ANW APPROVED.mp4";

const API = "https://api.freseniusvienna2024.com/Endpoint"
//const API = "http://192.168.0.19:8150"

const instructions = { "Medical Brochures": "Click to download the document(s):", "Expert Interviews": "Play below Interviews from Inflammatory Experts:" };
const VideoArray = [videoJoao, videoJerome, videoEugen, videoMarco, videoSarah, videoAntho];
const VideoName = ["MI20567 FK FRAME Budapest 01 Pr FONESCA ANW APPROVED.mp4",
  "MI20567 FK FRAME Budapest 05 Pr JEROME AVOUAC ANW APPROVED.mp4",
  "MI20567 FK FRAME Budapest 07 Pr EUGEN FEIST ANW APPROVED.mp4",
  "MI20567 FK FRAME Budapest 02 Pr GATTORNO ANW APPROVED.mp4",
  "MI20567 FK FRAME Budapest 06 Pr SARAH MACKIE ANW APPROVED.mp4",
  "MI20567 FK FRAME Budapest 03 Pr ANTHONY GORDON ANW APPROVED.mp4"]

var Key = undefined;
var currentTab = "Download";

if (typeof window !== 'undefined') {
  const CookieKey = Cookies.get('Key_PROD');
  if (CookieKey) {
    Key = CookieKey;
    currentTab = Cookies.get("Tab_PROD")
    axios.post(API + "/LogOn", { key: Key, tab: currentTab })
      .then((r) => {
      })
      .catch((e) => {
      })
  }
  else {
    axios.get(API + "/getId")
      .then((r) => {
        if (r.status === 200) {
          Key = r.data.key;
          Cookies.set('Key_PROD', r.data.key, { expires: 15, });
          Cookies.set('Tab_PROD', "Download", { expires: 15 });
        }
      })
      .catch((e) => {
      })
  }
}

function App() {
  const [selectedTab, setSelectedTab] = useState("Medical Brochures")
  const [email, setEmail] = useState("")
  const [selectedVid, setSelectedVid] = useState(undefined)
  const [selectedFile, setSelectedFile] = useState(undefined)
  const [selectedDownload, setSelectedDownload] = useState([])

  useEffect(() => {
    if (currentTab !== "Download") setSelectedTab("Expert Interviews")
  }, [currentTab])

  const confirmBlock = () => {
    return (
      <div className='BlockConfirm'>
        <div className='Confirm'>
          <div className='ConfirmText'>
            To send you our brochure of your choice we
            collect your email address and will store it for 30
            days. Your email address will be stored in secured
            datacenter in France and will be deleted after 30
            days.
          </div>
          <div className='ConfirmText'>
            To know your privacy rights and how we process
            personal data please read Fresenius Kabi Data
            Protection Statement for Healthcare
            Professionals here <a rel='noreferrer' target='_blank' href='https://www.fresenius-kabi.com/data-protection'>Data Protection Statement for
              Healthcare Professionals - Fresenius Kabi
              Biosimilars (fresenius-kabi.com)</a>
          </div>
          <div className='ConfirmText'>
            Clicking on SUBMIT, you agree with this Data
            Protection Statement and with processing your
            email address. You also agree to receive the
            brochure of your choice.
          </div>
          <input value={email} onChange={(v) => setEmail(v.currentTarget.value)} type='email' id='email' className='ConfirmText' style={{ boxSizing: "border-box", width: "100%", padding: 8, color: "#026BB4", border: "solid 4px #026BB4", borderRadius: 5, marginTop: 30 }} placeholder='ENTER EMAIL' />
          <div className='Button' onClick={() => sendDownloadList()}>
            SUBMIT
          </div>
        </div>
      </div>
    )
  }

  const sendDownloadList = () => {
    if (email.length > 0) {
      if (email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
        if (selectedDownload.length > 0) {
          const downloadList = [];
          selectedDownload.forEach((v) => {
            const file = docuList[v];
            downloadList.push(file.name);
          })
          axios.post(API + "/download", { email: email, downloadList: downloadList, key: Key })
            .then((r) => {
              if (r.status === 200) {
                alert("You will receive your brochures on your email address in a few minutes.")
              }
            })
            .catch((e) => {
              alert("An error occured when you sent your email, please try again.")
            })
          console.log("ok", email, " : ", downloadList)
        }
        else alert("Select at least one file to download.");
      }
      else {
        alert("This email is not valid, check your email.")
      }
    }
    else {
      alert("Your email address is necessary to get the brochure of your choice.")
    }
  }

  const selectDownload = (v) => {
    if (selectedDownload.includes(v)) {
      var newDownload = selectedDownload;
      const i = newDownload.indexOf(v);
      newDownload.splice(i, 1);
      setSelectedDownload(newDownload);
    }
    else {
      var newDownload = selectedDownload;
      newDownload.push(v);
      setSelectedDownload(newDownload);
    }
    console.log("Download", selectedDownload, email)
  }

  const FctSelectFile = (v) => {
    axios.post(API + "/ReadDocument", { key: Key, file: docuList[v].name })
      .then((r) => {
      })
      .catch((e) => {
      })
    //setSelectedFile(v)
  }

  const Link = () => {
    return (
      <div>
        <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto", marginTop: 50 }}>
          {
            docuList.map((element, index) => {
              return (
                <div key={"File" + index} style={{ marginTop: 60 }}>
                  <Document documentId={index} name={element.name} onPressAction={(v) => selectDownload(v)} setViewFile={(v) => FctSelectFile(v)} docuLink={docuList[index].url} />
                </div>
              )
            })
          }
        </div>
        {confirmBlock()}
      </div>
    )
  }

  const changeTab = (tab) => {
    setSelectedTab(tab)
    var tabTag = 'Download'
    if (tab !== "Medical Brochures") tabTag = 'Video';
    Cookies.set('Tab_PROD', tabTag, { expires: 15 });
    axios.post(API + "/GoTab", { key: Key, tab: tabTag })
      .then((r) => {
      })
      .catch((e) => {
      })
  }

  const ChangeVideo = (idVid) => {
    setSelectedVid(idVid)
    axios.post(API + "/LaunchVideo", { key: Key, video: VideoName[idVid] })
      .then((r) => {
      })
      .catch((e) => {
      })
  }

  const onProgressVideo = (percent, timeView) => {
    axios.post(API + "/WatchVideo", { key: Key, video: VideoName[selectedVid], viewTime: timeView, percent: percent })
      .then((r) => {
      })
      .catch((e) => {
      })
  }

  const Video = () => {
    return (
      <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto", marginTop: 50 }}>
        <div>
          <div className='videoMainWrapper'>
            <VideoMinia setVideo={ChangeVideo} videoId={0} name={"Professor João Eurico da Fonseca"} desc={"RA and Tocilizumab, the best patient profile from clinical experience and guidelines"} img={miniaJoao} />
          </div>
          <div className='videoMainWrapper'>
            <VideoMinia setVideo={ChangeVideo} videoId={1} name={"Professor Jérôme Avouac"} desc={"From a clinical vignette to a translational approach using the cytokine profile. A new paradigm for RA treatment ?"} img={miniaJerome} />
          </div>
        </div>
        <div className='spaceVideo'>
          <div className='videoMainWrapper'>
            <VideoMinia setVideo={ChangeVideo} videoId={2} name={"Professor Eugen Feist"} desc={"Arthritis and osteoporosis, the double penalty of RA patients and the potential protecting role of Tocilizumab"} img={miniaEugen} />
          </div>
          <div className='videoMainWrapper'>
            <VideoMinia setVideo={ChangeVideo} videoId={3} name={"Professor Marco Gattorno"} desc={"What about the role of IL6 inhibition to control the disease activity in JIA?"} img={miniaMarco} />
          </div>
        </div>
        <div className='spaceVideo'>
          <div className='videoMainWrapper'>
            <VideoMinia setVideo={ChangeVideo} videoId={4} name={"Professor Sarah Mackie"} desc={"How to optimize the potential of IL6 inhibition in the treatment of GCA ? The Leeds experience"} img={miniaSarah} />
          </div>
          <div className='videoMainWrapper'>
            <VideoMinia setVideo={ChangeVideo} videoId={5} name={"Professor Anthony Gordon"} desc={"Evaluating IL6 inhibitors as treatments of COVID-19"} img={miniaAntho} />
          </div>
        </div>
      </div>
    )
  }

  if (selectedFile >= 0) {
    return (
      <div>
        <FileReader url={docuList[selectedFile].url} setQuit={setSelectedFile} />
      </div>
    );
  }
  else {
    return (
      <div className="App">
        {
          selectedVid >= 0 ? <VideoPlayer sendProgress={onProgressVideo} video={VideoArray[selectedVid]} setQuit={setSelectedVid} /> : null
        }
        <div className='mainBlock'>
          <div className='Logo'>
            <img src={Logo} style={{ width: "100%" }} />
          </div>
          <div className='ContentWrap'>
            <div>
              <Tab text={"Medical Brochures"} currentTab={selectedTab} onPressSet={changeTab} />
              <Tab text={"Expert Interviews"} currentTab={selectedTab} onPressSet={changeTab} />
            </div>
            <div className='BlockColor contentBlock'>
              <Instruction text={instructions[selectedTab]} />
              {
                selectedTab === "Medical Brochures" ? Link() : Video()
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
